<template>
    <div>
    <el-row class="mt-2 mb-0">
        <div class="selected-tags">
            <el-tag
                v-if="selectedEnergyObject.id"
                :key="selectedEnergyObject.id"
                closable
                size="small"
                @close="handleEnergyObjectDelete"
                class="mr-1 mb-1">
                {{selectedEnergyObject.name}}
            </el-tag>
      </div>
    </el-row>
    <el-row class="m-0" :gutter="20">
        <el-col>
        <pagination
            style="text-align: center"
            :total-count="totalCount"
            :page-size.sync="pageSize"
            :current-page.sync="pageNumber"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
        </el-col>
    </el-row>
    <div v-loading="loading">
      <el-container class="tree-container mb-2" :style="{'height' : tableHeight}">
        <el-radio-group v-model="selectedEnergyObject" >
          <el-tree :data="energyObjects"
                    default-expand-all
                    node-key="id"
                    ref="tree">
            <span class="custom-tree-node" slot-scope="{ node }">
              <div class="tdWraper">
                <el-radio :label="node.data" @change="handleRadioChange">
                  <span>
                    <FactoryIcon />
                  </span>
                  <span>{{ node.data.name }}</span>
                </el-radio>
              </div>
            </span>
          </el-tree>
        </el-radio-group>
      </el-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import energyObjectsApi from '@/api/nsi/energyObjects';
import pagination from '@/components/Ui/Pagination';
import formatters from '@/mixins/formatters.js';
import tableResize from '@/mixins/tableResize.js';
import FactoryIcon from '@/assets/icons/factory.svg';


export default {
  name: 'EnergyObjectsTable',
  props: {
    filterModel: { type: Object, default: () => {} },
    filterActive: { type: Boolean, default: () => true },
    data: { type: Object, default: () => null },
  },
  mixins: [formatters, tableResize],
  components: { pagination, FactoryIcon },
  data() {
    return {
      loading: false,
      energyObjects: [],
      pageNumber: 1,
      pageSize: 100,
      totalCount: 0,
      sortField: 'name',
      modalClass: 'primary-eq-eo-select-modal',
      tableSizeConstant: 150,
      sortDesc: false,
      dialogVisible: false,
      selectedEnergyObject: {},
    };
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
  },
  watch: {
    filterModel: {
      handler() {
        this.getEnergyObjects();
      },
      deep: true
    },
  },
  created() {
    if (this.data != null) {
      this.selectedEnergyObject = { id: this.data.id, name: this.data.value };
    }
  },
  async mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.getTableHeight();
    });
    await this.getEnergyObjects();
    this.tableHeight = this.getTableHeight();
  },
  methods: {
    async getEnergyObjects() {
      this.loading = true;
      const params = {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          nameFilter: this.filterModel.nameFilter,
          ownerOrganizationFilter: [],
          dcOrganizationFilter: [],
          isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
          isRuFilter: this.filterModel.isRuFilter,
          sortField: this.sortField,
          sortDesc: this.sortDesc
      };
      const res = await energyObjectsApi.getEnergyObjectsWithPagination(params);
      if (res.data) {
        this.energyObjects = res.data.items;
        this.totalCount = res.data.totalCount;

        let childs = [];
        childs = this.energyObjects.filter((x) => x.id === this.selectedEnergyObject.id);

        if (childs.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.selectedEnergyObject = childs[0];
        } 
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getEnergyObjects();
    },
    async handleCurrentChange() {
      await this.getEnergyObjects();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortDesc = null;
        this.sortField = null;
      } else {
        this.sortDesc = event.order === 'descending';
        this.sortField = event.prop;
      }

      await this.getEnergyObjects();
    },
    handleRadioChange(node) {
      this.selectedEnergyObject = node;
      this.$emit('on-update', this.selectedEnergyObject);
    },
    handleEnergyObjectDelete() {
        this.selectedEnergyObject = {};
        this.$emit('on-update', this.selectedEnergyObject);
    }
  }
};
</script>

<style scoped lang="scss">
.selected-tags {
  max-height: 5vh;
  overflow-x: auto;
}
.el-dialog__wrapper.el-dialog.el-dialog__body{
  padding: 20px;
}
.el-radio-group {
    font-size: initial !important;
}
.el-radio-group {
  width: 100%;
}
span.el-radio__label {
  padding-left: 5px;
}
span.el-radio__label>span {
  padding: 0px 5px;
}
span.el-radio__inner {
  margin: 0px 0px 9px 0px;
}
.el-tabs__header.is-top {
  margin: 0px !important;
}
button.el-button.el-button--text:first-child {
  margin-left: 0px;
}
.el-col>span {
  font-size: 12px;
}
button.el-button.el-button--text:hover {
  color: #3078bf;
}
.el-select {
  width: 100%;
}
span {
  // white-space: nowrap;
  vertical-align: middle;
}
.dialog-footer {
  text-align: center;
}
.scrollableTree {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 420px;
}
.tree-item>span{
  font-size: 14px;
}
.tree-item>span>i {
  margin: 0px 5px 0px 0px;
}
</style>
